@font-face {
  font-family: "AvenirRegular";
  src: url("./assets/fonts/AvenirRegular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirBold";
  src: url("./assets/fonts/AvenirBold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "AvenirIt";
  src: url("./assets/fonts/AvenirIt.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "JaguarExtraBold";
  src: url("./assets/fonts/Jaguar-ExtraBold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "AvenirNextRegular";
  src: url("./assets/fonts/AvenirNext-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AvenirNextBold";
  src: url("./assets/fonts/AvenirNextLTPro-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "AvenirNextIt";
  src: url("./assets/fonts/AvenirNextLTPro-It.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "LandRoverWeb";
  src: url("./assets/fonts/LandRoverWeb-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "LandRoverOt4-medium";
  src: url("./assets/fonts/LandRoverOT4-Medium.woff2");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "AvenirNextW01Regular";
  src: url("./assets/fonts/AvenirNextW01-Regular.woff");
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "AvenirRegular",
    "LandRoverOt4-medium" "AvenirNextRegular", "AvenirNextW01Regular",
    "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h5 {
  font-family: "LandRoverOt4-medium";
  font-size: 16px;
  font-weight: 800;
}
h6 {
  font-family: "LandRoverOt4-medium";
  font-size: 16px;
  font-weight: 800;
}
p {
  font-family: "AvenirNextRegular";
  font-size: 12px;
}
span {
  font-family: "AvenirNextRegular";
  font-size: 12px;
}
.container {
  margin: 0;
  padding: 15px;
}
hr {
  width: 95%;
}
.swiper-button-next {
  color: #fff !important;
  font-size: 20px;
  margin-top: -90px !important;
}
.swiper-button-prev {
  color: #fff !important;
  font-size: 20px;
  margin-top: -90px !important;
}
.MuiBackdrop-root {
  z-index: 1 !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #000 !important;
}
.input-box {
  width: 100%;
  height: 2.5rem;
  padding: 11px 12px 11px 12px;
  margin-top: 5px;
  border: 1px solid #bbbbbb;
}
.error_message {
  color: red;
}
button {
  height: 3rem;
  background-color: #0c121c;
  padding: 10px;
  color: #fff;
  border: none;
  cursor: pointer;
}
button:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}
video {
  width: 100%;
}
.MuiStepIcon-root {
  width: 25px !important;
}
.MuiStepIcon-root.MuiStepIcon-completed {
  color: #4a4f54 !important;
  width: 25px !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: #4a4f54 !important;
  width: 25px !important;
}
.MuiStepIcon-root.Mui-completed {
  color: #4a4f54 !important;
  width: 25px !important;
}
.MuiStepIcon-root.Mui-active {
  color: #4a4f54 !important;
  width: 25px !important;
}
.MuiStepContent-root {
  border-left: none !important;
}
.MuiAccordion-root:before {
  top: 0 !important;
}
.Mui-selected {
  color: #000 !important;
}
.MuiTabs-indicator {
  background-color: #fff !important;
}
button:focus {
  outline: none;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #4a4f54;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .swiper-button-next {
    /* visibility: hidden; */
  }

  .swiper-button-prev {
    /* visibility: hidden; */
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 850px) {
  .swiper-button-next {
    margin-top: 5px !important;
  }

  .swiper-button-prev {
    margin-top: 5px !important;
  }
}
.container_padding {
  padding: 1rem 3rem;
}
